export default {
  queryRouteList: '/routes',

  queryUserInfo: '/user',
  logoutUser: '/user/logout',
  loginUser: 'POST /user/login',
  changePass: 'PATCH /user/changePass',

  queryUser: '/user/:id',
  queryUserList: '/users',
  updateUser: 'PATCH /user/:id',
  createUser: 'POST /user',
  removeUser: 'DELETE /user/:id',
  removeUserList: 'POST /users/delete',

  queryProduct: '/product/:id',
  queryProductList: '/products',
  updateProduct: 'PATCH /product/:id',
  createProduct: 'POST /product',
  removeProduct: 'DELETE /product/:id',
  removeProductList: 'POST /products/delete',

  createProductBuyList: 'POST /productsbuy',

  queryKind: '/kind/:id',
  queryKindList: '/kinds',
  updateKind: 'PATCH /kind/:id',
  createKind: 'POST /kind',
  addKind: 'POST /kind/add',
  removeKind: 'DELETE /kind/:id',
  removeKindList: 'POST /kinds/delete',

  queryStore: '/store/:id',
  queryStoreList: '/stores',
  updateStore: 'PATCH /store/:id',
  createStore: 'POST /store',
  removeStore: 'DELETE /store/:id',
  removeStoreList: 'POST /stores/delete',

  queryPayment: '/payment/:id',
  queryPaymentList: '/payments',
  queryPaymentListExcel: '/payments/excel',
  updatePayment: 'PATCH /payment/:id',
  createPayment: 'POST /payment',
  createPaymentItem: 'POST /paymentitems',
  removePayment: 'DELETE /payment/:id',
  removePaymentList: 'POST /payments/delete',

  queryStatisticsList: '/statistics',
  queryStatisticsListExcel: '/statistics/excel',

  queryDashboard: '/dashboard',
  queryMainBoard: '/dashboard/board',
  queryMainStore: '/dashboard/store',
  createBoard: 'POST /dashboard/board',
  updateBoard: 'PATCH /dashboard/board/:id',
  removeBoard: 'DELETE /dashboard/board/:id',

  queryComboKind: '/combo/kind',
  queryComboProduct: '/combo/product',
  queryComboBuyItem: '/combo/buyitem',
}
