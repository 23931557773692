module.exports = {
  siteName: 'THE FOREST',
  copyright: ' ©2019 THE FOREST by StayYoung',
  logoPath: '/logo.svg',
  apiPrefix: '/api/v2',
  fixedHeader: true,

  layouts: [
    {
      name: 'primary',
      include: [/.*/],
      exclude: [/(\/(en|zh))*\/login/],
    },
  ],
}
