import { cloneDeep } from 'lodash'
import umiRouter from 'umi/router'
import pathToRegexp from 'path-to-regexp'
import ExportJsonExcel from 'js-export-excel'

export function arrayToTree(
  array,
  id = 'id',
  parentId = 'pid',
  children = 'children'
) {
  const result = []
  const hash = {}
  const data = cloneDeep(array)

  data.forEach((item, index) => {
    hash[data[index][id]] = data[index]
  })

  data.forEach(item => {
    const hashParent = hash[item[parentId]]
    if (hashParent) {
      !hashParent[children] && (hashParent[children] = [])
      hashParent[children].push(item)
    } else {
      result.push(item)
    }
  })
  return result
}

export const router = umiRouter
export function pathMatchRegexp(regexp, pathname) {
  return pathToRegexp(regexp).exec(pathname ? pathname : '')
}

export function queryAncestors(array, current, parentId, id = 'id') {
  const result = [current]
  const hashMap = new Map()
  array.forEach(item => hashMap.set(item[id], item))

  const getPath = current => {
    const currentParentId = hashMap.get(current[id])[parentId]
    if (currentParentId) {
      result.push(hashMap.get(currentParentId))
      getPath(hashMap.get(currentParentId))
    }
  }

  getPath(current)
  return result
}

export function queryLayout(layouts, pathname) {
  let result = 'public'

  const isMatch = regepx => {
    return regepx instanceof RegExp
      ? regepx.test(pathname)
      : pathMatchRegexp(regepx, pathname)
  }

  for (const item of layouts) {
    let include = false
    let exclude = false
    if (item.include) {
      for (const regepx of item.include) {
        if (isMatch(regepx)) {
          include = true
          break
        }
      }
    }

    if (include && item.exclude) {
      for (const regepx of item.exclude) {
        if (isMatch(regepx)) {
          exclude = true
          break
        }
      }
    }

    if (include && !exclude) {
      result = item.name
      break
    }
  }

  return result
}

export function downloadExcel(list, column, name) {
  const data = list
  if (!data || data.length < 1) return

  let dataTable = [],
    keyMap = [],
    titleMap = []
  column.map((e, idx) => {
    if (column[idx].isHeader) {
      keyMap.push(e.key)
      titleMap.push(e.title)
    }
  })

  for (let idx in data) {
    let obj = {}
    keyMap.map(e => {
      if (e === 'tel')
        return (obj[e] = data[idx][e].replace(
          /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
          '$1-$2-$3'
        ))
      return (obj[e] = data[idx][e])
    })
    dataTable.push(obj)
  }

  let option = {}
  option.fileName = name
  option.datas = [
    {
      sheetName: name,
      sheetData: dataTable,
      sheetFilter: keyMap,
      sheetHeader: titleMap,
    },
  ]
  const toExcel = new ExportJsonExcel(option)
  toExcel.saveExcel()
}
