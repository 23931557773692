import React from 'react';
import {
  Router as DefaultRouter,
  Route,
  Switch,
  StaticRouter,
} from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@@/history';
import _dvaDynamic from 'dva/dynamic';

const Router = require('dva/router').routerRedux.ConnectedRouter;

const routes = [
  {
    path: '/',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__index" */ '../../layouts/index.js'),
          LoadingComponent: require('/home/ubuntu/newbarcode/src/components/Loader/Loader')
            .default,
        })
      : require('../../layouts/index.js').default,
    routes: [
      {
        path: '/404',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__404" */ '../404.js'),
              LoadingComponent: require('/home/ubuntu/newbarcode/src/components/Loader/Loader')
                .default,
            })
          : require('../404.js').default,
      },
      {
        path: '/dashboard',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__dashboard__model.js' */ '/home/ubuntu/newbarcode/src/pages/dashboard/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__dashboard__index" */ '../dashboard/index.js'),
              LoadingComponent: require('/home/ubuntu/newbarcode/src/components/Loader/Loader')
                .default,
            })
          : require('../dashboard/index.js').default,
      },
      {
        path: '/',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__index" */ '../index.js'),
              LoadingComponent: require('/home/ubuntu/newbarcode/src/components/Loader/Loader')
                .default,
            })
          : require('../index.js').default,
      },
      {
        path: '/kind',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__kind__model.js' */ '/home/ubuntu/newbarcode/src/pages/kind/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__kind__index" */ '../kind/index.js'),
              LoadingComponent: require('/home/ubuntu/newbarcode/src/components/Loader/Loader')
                .default,
            })
          : require('../kind/index.js').default,
      },
      {
        path: '/login',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__login__model.js' */ '/home/ubuntu/newbarcode/src/pages/login/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__login__index" */ '../login/index.js'),
              LoadingComponent: require('/home/ubuntu/newbarcode/src/components/Loader/Loader')
                .default,
            })
          : require('../login/index.js').default,
      },
      {
        path: '/password',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__password__model.js' */ '/home/ubuntu/newbarcode/src/pages/password/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__password__index" */ '../password/index.js'),
              LoadingComponent: require('/home/ubuntu/newbarcode/src/components/Loader/Loader')
                .default,
            })
          : require('../password/index.js').default,
      },
      {
        path: '/payment/addMulti',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__payment__addMulti__model.js' */ '/home/ubuntu/newbarcode/src/pages/payment/addMulti/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__payment__model.js' */ '/home/ubuntu/newbarcode/src/pages/payment/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__payment__addMulti__index" */ '../payment/addMulti/index.js'),
              LoadingComponent: require('/home/ubuntu/newbarcode/src/components/Loader/Loader')
                .default,
            })
          : require('../payment/addMulti/index.js').default,
      },
      {
        path: '/payment',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__payment__model.js' */ '/home/ubuntu/newbarcode/src/pages/payment/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__payment__index" */ '../payment/index.js'),
              LoadingComponent: require('/home/ubuntu/newbarcode/src/components/Loader/Loader')
                .default,
            })
          : require('../payment/index.js').default,
      },
      {
        path: '/product',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__product__model.js' */ '/home/ubuntu/newbarcode/src/pages/product/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__product__index" */ '../product/index.js'),
              LoadingComponent: require('/home/ubuntu/newbarcode/src/components/Loader/Loader')
                .default,
            })
          : require('../product/index.js').default,
      },
      {
        path: '/product/:id',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__product__$id__models__detail.js' */ '/home/ubuntu/newbarcode/src/pages/product/$id/models/detail.js').then(
                  m => {
                    return { namespace: 'detail', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__product__model.js' */ '/home/ubuntu/newbarcode/src/pages/product/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__product___id__index" */ '../product/$id/index.js'),
              LoadingComponent: require('/home/ubuntu/newbarcode/src/components/Loader/Loader')
                .default,
            })
          : require('../product/$id/index.js').default,
      },
      {
        path: '/productbuy',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__productbuy__model.js' */ '/home/ubuntu/newbarcode/src/pages/productbuy/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__productbuy__index" */ '../productbuy/index.js'),
              LoadingComponent: require('/home/ubuntu/newbarcode/src/components/Loader/Loader')
                .default,
            })
          : require('../productbuy/index.js').default,
      },
      {
        path: '/statistics',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__statistics__model.js' */ '/home/ubuntu/newbarcode/src/pages/statistics/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__statistics__index" */ '../statistics/index.js'),
              LoadingComponent: require('/home/ubuntu/newbarcode/src/components/Loader/Loader')
                .default,
            })
          : require('../statistics/index.js').default,
      },
      {
        path: '/store',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__store__model.js' */ '/home/ubuntu/newbarcode/src/pages/store/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__store__index" */ '../store/index.js'),
              LoadingComponent: require('/home/ubuntu/newbarcode/src/components/Loader/Loader')
                .default,
            })
          : require('../store/index.js').default,
      },
      {
        path: '/user',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__user__model.js' */ '/home/ubuntu/newbarcode/src/pages/user/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__user__index" */ '../user/index.js'),
              LoadingComponent: require('/home/ubuntu/newbarcode/src/components/Loader/Loader')
                .default,
            })
          : require('../user/index.js').default,
      },
      {
        component: () =>
          React.createElement(
            require('/home/ubuntu/newbarcode/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
              .default,
            { pagesPath: 'src/pages', hasRoutesInConfig: false },
          ),
      },
    ],
  },
  {
    component: () =>
      React.createElement(
        require('/home/ubuntu/newbarcode/node_modules/umi-build-dev/lib/plugins/404/NotFound.js')
          .default,
        { pagesPath: 'src/pages', hasRoutesInConfig: false },
      ),
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    // dva 中 history.listen 会初始执行一次
    // 这里排除掉 dva 的场景，可以避免 onRouteChange 在启用 dva 后的初始加载时被多执行一次
    const isDva =
      history.listen
        .toString()
        .indexOf('callback(history.location, history.action)') > -1;
    if (!isDva) {
      routeChangeHandler(history.location);
    }
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return <Router history={history}>{renderRoutes(routes, props)}</Router>;
  }
}
